import * as React from 'react'
import { Paper, InputBase, Button } from '@material-ui/core'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { PaperProps } from '@material-ui/core/Paper'
import { InputProps } from '@material-ui/core/Input'
import { ButtonProps } from '@material-ui/core/Button'

const SubscribeContainer = styled.div`
  display: flex;
  margin-top: 2em;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const SubscribePaper = (props: PaperProps) => (
  <Paper
    {...props}
    css={css`
      padding: 4px 4px;
      display: flex;
      align-items: 'center';
      width: 25em;
      @media (max-width: 700px) {
        width: 90%;
      }
    `}
  />
)

const BuyContainer = styled.div`
  padding: 4px 4px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 25em;
  @media (max-width: 700px) {
    width: 90%;
  }
  margin-bottom: 3em;
`

const SubscribeInput = (props: InputProps) => (
  <InputBase
    {...props}
    css={css`
      flex: 1;
    `}
  />
)

const SubscribeButton = (props: ButtonProps) => (
  <Button
    {...props}
    css={css`
      background-color: rgb(126, 191, 0);
    `}
  />
)

const SuccessText = styled.h2`
  color: rgb(38, 133, 0);
`

const SubscribeForm: React.SFC = () => {
  const [subscribed, setSubscribed] = React.useState(false)
  const [email, setEmail] = React.useState<string>('')

  const subscribe = () => {
    addToMailchimp(email).then(() => {
      setSubscribed(true)

      if ((window as any).ga) {
        ;(window as any).ga('send', 'event', {
          eventCategory: 'Subscribe',
          eventAction: 'Clicked',
          eventLabel: email
        })
      }
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  return (
    <SubscribeContainer>
      {subscribed ? (
        <SuccessText>Thanks for subscribing!</SuccessText>
      ) : (
        <>
          <span>Subscribe for updates about sales and new books</span>
          <SubscribePaper elevation={1}>
            <SubscribeInput placeholder="Email" onChange={handleChange} />
            <SubscribeButton variant="contained" data-button-id="subscribe" aria-label="Notify Me" onClick={subscribe}>
              Notify me
            </SubscribeButton>
          </SubscribePaper>
        </>
      )}
    </SubscribeContainer>
  )
}

export default SubscribeForm
