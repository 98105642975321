import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import css from '@emotion/css'

const AMAZON_BOOK_URL =
  'https://www.amazon.com/gp/product/B07NK2X64G/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07NK2X64G&linkCode=as2&tag=fionashores-buy-button-20&linkId=f97e6a92cb8f79f9381934a97708a5b6'

const openAmazonPage = () => {
  document.location.href = AMAZON_BOOK_URL
}

const clickBuy = (event: React.MouseEvent<HTMLInputElement>) => {
  if ((window as any).ga) {
    event.preventDefault()
    ;(window as any).ga('send', 'event', {
      eventCategory: 'Buy',
      eventAction: 'Clicked',
      hitCallback: openAmazonPage
    })
    setTimeout(openAmazonPage, 1000)
  }
}

export const BuyButton = (props: ButtonProps) => (
  <Button
    onClick={clickBuy}
    data-button-id="buy"
    variant="contained"
    href={AMAZON_BOOK_URL}
    {...props}
    css={css`
      background-color: rgb(126, 191, 0);
      font-size: 2em;
      white-space: nowrap;
    `}
  >
    Buy Now
  </Button>
)
