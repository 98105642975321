import * as React from 'react'
import { Grid } from '@material-ui/core'
import styled from '@emotion/styled'

const HeaderContainer = styled.div`
  background-color: rgb(232, 236, 237);
  border-bottom: 0.15em solid white;
  font-family: 'Raleway', sans-serif;
`

const AuthorName = styled.h1`
  color: rgb(212, 46, 70);
  font-size: 4em;
  margin: 0.3em;

  @media (max-width: 700px) {
    font-size: 2em;
  }
`

const SubHeading = styled.h4`
  margin-top: 1em;
  margin-bottom: 0em;
`

const Header: React.SFC = () => (
  <HeaderContainer>
    <Grid container justify="center" alignItems="center" direction="column">
      <Grid item>
        <SubHeading>New Contemporary Romance Author</SubHeading>
      </Grid>
      <Grid item>
        <AuthorName>Fiona Shores</AuthorName>
      </Grid>
    </Grid>
  </HeaderContainer>
)

export default Header
