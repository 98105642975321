import * as React from 'react'

import Page from '../components/Page'
import styled from '@emotion/styled'
import semiBoldFont from '../content/fonts/HoneyScript-SemiBold.ttf'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SubscribeForm from '../components/Subscribe'
import { BuyButton } from './BuyButton'

const AMAZON_BOOK_IMAGE_URL = 'https://amzn.to/2UgiWPh'

const Blurb = styled.p`
  font-size: 1.2em;
`

const ImageContainer = styled.div`
  margin-top: 1em;
  margin-right: 2em;
`

const InfoContainer = styled.div`
  flex-basis: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 700px) {
    margin-top: 1em;
    flex-basis: 75%;
  }
`

const BuyContainer = styled.div`
  @media (max-width: 700px) {
    order: -1;
  }
`

const BookTitle = styled.h2`
  @font-face {
    font-display: auto;
    font-family: 'HoneyScript SemiBold';
    src: url(${semiBoldFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  font-family: 'HoneyScript SemiBold';
  font-size: 4em;
  line-height: 1em;
  margin: 0.2em;
  text-align: center;
`
const IMAGE_QUERY = graphql`
  query {
    bookCover: file(relativePath: { eq: "images/book-cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    background: file(relativePath: { eq: "images/hero-background.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    backgroundMobile: file(relativePath: { eq: "images/hero-background-mobile.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 450) {
          src
        }
      }
    }
  }
`

const Main = ({ isAmp }: { isAmp: boolean }) => {
  const imageData = useStaticQuery(IMAGE_QUERY)

  const HeroContainer = styled.div`
    background-image: url(${imageData.background.childImageSharp.fluid.src});
    background-size: cover;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding-bottom: 1em;
    justify-content: center;
    @media (max-width: 450px) {
      background-image: url(${imageData.backgroundMobile.childImageSharp.fluid.src});
    }
  `

  const bookCover = !isAmp ? (
    <Img fixed={imageData.bookCover.childImageSharp.fixed} />
  ) : (
    <amp-img
      srcset={imageData.bookCover.childImageSharp.fixed.srcSet}
      src={imageData.bookCover.childImageSharp.fixed.src}
      width={imageData.bookCover.childImageSharp.fixed.width}
      height={imageData.bookCover.childImageSharp.fixed.height}
      alt={imageData.bookCover.childImageSharp.fixed.altText}
    />
  )

  return (
    <Page>
      <HeroContainer>
        <ImageContainer>
          <a href={AMAZON_BOOK_IMAGE_URL}>{bookCover}</a>
        </ImageContainer>
        <InfoContainer>
          <BookTitle style={{ font: semiBoldFont }}>Recipe for a Kiss</BookTitle>
          <BuyContainer>
            <BuyButton />
          </BuyContainer>
          <Blurb>
            Debut author Fiona Shores combines sweet, small-town romance with culinary delights. A satisfyingly scrumptious love story with
            a dash of Southern charm.
          </Blurb>
        </InfoContainer>
        <div />
        <SubscribeForm />
      </HeroContainer>
    </Page>
  )
}

export default Main
