import * as React from 'react'
import Header from './Header'
import { CssBaseline } from '@material-ui/core'
import Helmet from 'react-helmet'
import Footer from './Footer'

const Page: React.SFC = ({ children }) => (
  <CssBaseline>
    <div style={{ fontFamily: 'Raleway' }}>
      <Header />
      {children}
      <Footer />
    </div>
  </CssBaseline>
)

export default Page
