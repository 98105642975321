import * as React from 'react'
import { Grid } from '@material-ui/core'
import styled from '@emotion/styled'

import facebookIcon from '../content/images/facebook.png'
import twitterIcon from '../content/images/twitter.png'

const FooterContainer = styled.div`
  background-color: #424747;
  height: 100%;
`

const SocialIcon = styled.img`
  height: 32px;
  margin: 8px;
`

const Footer: React.SFC = () => (
  <FooterContainer>
    <Grid container justify="center" alignItems="center" direction="row">
      <a href="https://www.facebook.com/fionashoresauthor/" aria-label="See Fiona's Facebook page">
        <SocialIcon alt="Facebook" src={facebookIcon} />
      </a>
      <a href="https://twitter.com/FionaShores" aria-label="See Fiona's Twitter page">
        <SocialIcon alt="Twitter" src={twitterIcon} />
      </a>
    </Grid>
  </FooterContainer>
)

export default Footer
